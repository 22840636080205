$(() => {
  const navText = $('.c-mobile-nav__text')
  const menuIcon = $('.c-mobile-nav__menu')
  const closeIcon = $('.c-mobile-nav__closed')

  var isActive = false
  const menuOpen = function () {
    if (isActive) {
      $('body').addClass('state-menu--open')
      isActive = false

      navText.text('close')
      menuIcon.hide()
      closeIcon.show()
    } else {
      $('body').removeClass('state-menu--open')
      isActive = true

      navText.text('menu')
      closeIcon.hide()
      menuIcon.show()
    }
  }
  $('#c-mobile-nav__trigger').click(function () {
    menuOpen()
  })
  menuOpen()
})
